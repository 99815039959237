import React from "react";
import { EmotionStyles } from "../../../interfaces/emotion";
import { variables } from "../../../styles/variables";
import TimeElapsed from "./TimeElapsed";
import { usePlayer } from "../../../contexts/PlayerContextV2/PlayerContextV2";
import { TransistorEpisodeInterface } from "../../../interfaces/podcasts";

const styles: EmotionStyles = {
  playerTime: {
    margin: `${variables.spacing.medium} 0`
  }
};

const InlineTimeElapsed = ({
  episode
}: {
  episode: TransistorEpisodeInterface;
}) => {
  const {
    state: { playedSeconds, currentPlaying }
  } = usePlayer();
  return (
    <div>
      <div css={styles.playerTime}>
        <TimeElapsed
          elapsed={
            currentPlaying && currentPlaying.episode.id === episode.id
              ? playedSeconds
              : "00:00"
          }
          totalDuration={episode.attributes.duration_in_mmss}
        />
      </div>
    </div>
  );
};

export default InlineTimeElapsed;
