import React, { Fragment } from "react";
import { Link } from "gatsby";

import Heading from "../Heading";
import Inner from "../Inner";
import EpisodeImageTile from "../Episode/components/EpisodeImageTile";
import IconsBar from "../Episode/components/IconsBar";
import InlinePlayer from "../Player/components/InlinePlayer.tsx";
import { TransistorEpisodeInterface } from "../../interfaces/podcasts";
import MinimizeDismissControls, {
  MinimizeDismissControlsProps
} from "../MinimizeDismissControls";

import { styles } from "./styles";
import BuyMeACoffee from "../BuyMeACoffee";
import { Transcript } from "../../interfaces/transcript";
import PortableText from "../PortableText";
import TranscriptTabs from "./components/TranscriptPageTabs";
import InlineTimeElapsed from "../Player/components/InlineTimeElapsed";

interface TranscriptPageProps extends MinimizeDismissControlsProps {
  episode: TransistorEpisodeInterface;
  transcript: Transcript;
  transcripts: Transcript[];
}

const TranscriptPage: React.FC<TranscriptPageProps> = ({
  episode,
  transcript,
  transcripts,
  onMinimize,
  onDismiss
}) => {
  const {
    image,
    attributes: { title, media_url, season }
  } = episode;
  const { _rawBody } = transcript;
  const SeriesLink = () => (
    <p>
      This episode is from Series {season} of{" "}
      <Link to="/" onClick={onMinimize ? () => onMinimize() : undefined}>
        Why Mums Don't Jump
      </Link>
    </p>
  );

  return (
    <div css={styles.container}>
      <Inner cssStyles={[styles.inner]}>
        <div css={styles.box}>
          <MinimizeDismissControls
            onMinimize={onMinimize}
            onDismiss={onDismiss}
            containerStyles={styles.closeIcons}
          />
          <div css={styles.grid}>
            <div css={styles.image}>
              <EpisodeImageTile season={season} image={image} />
            </div>
            <div>
              <Heading headingLevel="h1" styles={styles.heading} italic={false}>
                {title} - Episode Transcript
              </Heading>
              <InlinePlayer episode={episode} />
              <InlineTimeElapsed episode={episode} />
              <TranscriptTabs
                episode={episode}
                activeTab="transcript"
                transcripts={transcripts}
              />
              <div css={styles.description}>
                <Fragment>
                  <PortableText blocks={_rawBody} />
                  <br />
                  <SeriesLink />
                  <BuyMeACoffee />
                </Fragment>
                <IconsBar
                  mediaUrl={media_url}
                  title={title}
                  downloadText="Download episode"
                />
              </div>
            </div>
          </div>
        </div>
      </Inner>
    </div>
  );
};

export default TranscriptPage;
