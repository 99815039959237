import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList, {
  GraphQLError
} from "../components/graphql-error-list";
import SEO from "../components/SEO";
import { prettifySlug } from "../helpers/prettifySlug";
import { TransistorEpisodeInterface } from "../interfaces/podcasts";
import { SiteInterface } from "../pages";
import { createEpisodeSchema } from "../components/SEO/helpers/createEpisodeSchema";
import { Transcript } from "../interfaces/transcript";
import { getTranscript } from "../helpers/getTranscript";
import TranscriptPage from "../components/TranscriptPage";
import { NodesInterface } from "../interfaces/blogPost";

export const query = graphql`
  query TranscriptPageTemplateQuery($id: String!) {
    episode: transistorEpisode(id: { eq: $id }) {
      id
      image {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      ...TransistorEpisodeAttributes
    }
    transcripts: allSanityTranscript {
      nodes {
        id
        episode {
          episode
        }
        createTranscriptPage
        _rawBody
      }
    }
    site: sanitySiteSettings {
      title
      keywords
    }
  }
`;

interface EpisodeTemplateProps {
  data: {
    episode: TransistorEpisodeInterface;
    transcripts: NodesInterface<Transcript>;
    site: Omit<SiteInterface, "description">;
  };
  pageContext: {
    id: string;
    index: number;
    title: string;
  };
  errors: Array<GraphQLError>;
}

const createTranscriptPath = (season: number, title: string) =>
  `/episodes/series-${season}/${prettifySlug(title)}/transcript`;

const TranscriptTemplate: React.FC<EpisodeTemplateProps> = ({
  data,
  errors
}) => {
  const { attributes } = data && data.episode;
  const { nodes: transcripts } = data && data.transcripts;
  const episodeTranscript = getTranscript(attributes.title, transcripts);
  const siteUrl = "https://whymumsdontjump.com";
  const keywords = attributes.keywords
    ? attributes.keywords.split(", ")
    : data.site.keywords || [];
  return (
    <React.Fragment>
      {errors && <SEO title="GraphQL Error" />}
      {data.episode && data.site && episodeTranscript && (
        <SEO
          title={`${attributes.title || "Untitled"} | ${data.site.title}`}
          description={attributes.formatted_summary}
          keywords={keywords}
          schema={createEpisodeSchema({
            attributes,
            siteUrl,
            url: `${siteUrl}${createTranscriptPath(
              attributes.season,
              attributes.title
            )}`
          })}
        />
      )}

      {errors && <GraphQLErrorList errors={errors} />}
      {data.episode && episodeTranscript && (
        <TranscriptPage
          episode={data.episode}
          transcript={episodeTranscript}
          transcripts={transcripts}
        />
      )}
    </React.Fragment>
  );
};

export default TranscriptTemplate;
